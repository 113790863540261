import { useLazyQuery } from '@apollo/client';
import { ErrorState, PopoutFilterPluginMinimumAge } from '@dt/components';
import { SupplyChainSecureAppTypeEnumValues } from '@dt/graphql-support/enums';
import assets_search_filter_values from '@dt/graphql-support/horizon/assets_search_filter_values';
import policy_rules from '@dt/graphql-support/horizon/policy_rules';
import postman_protect from '@dt/graphql-support/horizon/ts/postman_protect';
import { sast_issues_search_filter_values } from '@dt/graphql-support/horizon/ts/sast';
import { web_changes_search_filter_values } from '@dt/graphql-support/horizon/ts/web_changes';
import { security_events_filter_values } from '@dt/graphql-support/public/api_protect/ts/charts';
import mobile_phishing from '@dt/graphql-support/public/caprica/mobile_phishing';
import { risky_devices_search_filter_values } from '@dt/graphql-support/public/mobile_protect/ts/anti_fraud';
import { security_findings_search_filter_values } from '@dt/graphql-support/sevenhell/ts/security_findings_filter_values';
import { palette } from '@dt/theme';
import { Box, Button, Card, List, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useQuery as useTanstackQuery } from '@tanstack/react-query';
import { memo, useEffect, useState } from 'react';
import getPrivacyPleaseFullDataReportFilter from '../../../queries/privacyPlease/getPrivacyPleaseFullDataReportFilters';
import TableFiltersSelectLabel from './TableFiltersSelectLabel';
import TableFilterMyApps from './filters/TableFilterMyApps';
import TableFilterTestsStatus from './filters/TableFilterTestsStatus';
import TableFilterVendors from './filters/TableFilterVendors';
import TableFiltersAppType from './filters/TableFiltersAppType';
import TableFiltersAssetGroup from './filters/TableFiltersAssetGroup';
import TableFiltersAssetTag from './filters/TableFiltersAssetTag';
import TableFiltersAssetTypeGroup from './filters/TableFiltersAssetTypeGroup';
import TableFiltersDateRange from './filters/TableFiltersDateRange';
import TableFiltersFilterGroup from './filters/TableFiltersFilterGroup';
import TableFiltersKeyword from './filters/TableFiltersKeyword';
import TableFiltersListGroup from './filters/TableFiltersListGroup';
import TableFiltersSeverity from './filters/TableFiltersSeverity';
import TableFiltersShadowAssets from './filters/TableFiltersShadowAssets';
import TableFiltersSourceCodeRepository from './filters/TableFiltersSourceCodeRepository';

export const filterItems = {
  filter_by_active_protection_status: 'Active Protection Status',
  filter_by_age: 'Age',
  filter_by_app_type: 'App Type',
  filter_by_app_versions: 'App Versions',
  filter_by_asset_group_id: 'Asset Group',
  filter_by_asset_id: 'Source Code Repository',
  filter_by_asset_tags: 'Asset Tags',
  filter_by_asset_type: 'Asset Type',
  filter_by_asset_type_group: 'Third Party Asset Type',
  filter_by_belongs_to_products: 'Products',
  filter_by_cloud_provider: 'Hosted On',
  filter_by_cnapp_category: 'CNAPP Category',
  filter_by_compliance_policy: 'Compliance Policy',
  filter_by_connections: 'Connections',
  filter_by_countries: 'Country',
  filter_by_current_status: 'Current Status',
  filter_by_data_type: 'Data Type',
  filter_by_date_range: 'Date Range',
  filter_by_discovered_app_status: 'App Status',
  filter_by_discovered_application_keyword: 'Keyword',
  filter_by_discovered_via: 'Discovered via',
  filter_by_event_type: 'Event Type',
  filter_by_external_id: 'External ID',
  filter_by_fraud_activity: 'Fraud Activity',
  filter_by_fraud_indicators: 'Fraud Signals',
  filter_by_fraud_score: 'Fraud Score',
  filter_by_hacker_toolkit: 'Hacker Toolkit',
  filter_by_has_policy_violations_created_on_month: 'Policy Violations Created On Month',
  filter_by_has_policy_violations_with_status: 'Status',
  filter_by_http_method: 'HTTP Method',
  filter_by_infringement_level: 'Fraudulent Behavior',
  filter_by_is_priority_alert: 'Is Priority Alert',
  filter_by_is_shadow: 'Shadow Assets',
  filter_by_manifest_data_type: 'Manifest Data Type',
  filter_by_manifest_sdk_included: 'Manifest SDK Included',
  filter_by_mobile_app: 'Mobile App',
  filter_by_no_asset_group: 'Ungrouped Asset',
  filter_by_oss_sdk: 'OSS/SDK',
  filter_by_platform: 'Platform',
  filter_by_policy_rule_type_id: 'Policy Rule Type',
  filter_by_priority: 'Priority',
  filter_by_related_first_party_asset_id: '',
  filter_by_related_first_party_asset_type: 'Asset Type',
  filter_by_release_type: 'Release Type',
  filter_by_report_status: 'Report Status',
  filter_by_sast_issue_type: 'Issue Source',
  filter_by_severity: 'Severity',
  filter_by_source_country_code: 'Country Code',
  filter_by_store_blocker: 'Store Blocker',
  filter_by_store_category: 'Apple/Google Category',
  filter_by_subscription: 'Subscription',
  filter_by_team_name: 'Teams',
  filter_by_text: 'Keyword',
  filter_by_third_party_destinations: 'Third-Party Destinations',
  filter_by_total_events: 'Total Events',
  filter_by_triaging_decision: 'Action',
  filter_by_type: 'Issue Source',
  filter_by_user_id: 'User ID',
  filter_by_uses_third_party_vendor_id: 'Vendor',
  filter_by_vulnerable_to_toolkit_hacks: 'Hack Status',
  filter_by_web_app: 'Web App',
  filter_by_web_change_type: 'Web Change Type',
  filter_by_workspace_name: 'Workspaces',
  filter_tests_by_status: 'Status',
};

const useStyles = makeStyles(theme => ({
  box: {
    minHeight: 350,
    overflow: 'scroll',
    padding: theme.spacing(1),
    width: 500,
  },
  footer: {
    borderTop: `1px solid ${palette.gray45}`,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
  },
  list: {
    '& > li': {
      '&:hover': {
        background: palette.gray50,
      },
      cursor: 'pointer',
    },
    padding: 0,
  },
  nav: {
    borderRight: `1px solid ${palette.gray45}`,
    overflow: 'scroll',
    width: 350,
  },
}));

const AssetFilterPopper = ({
  assetsSearchFilterValuesQueryParams,
  filterEnums,
  filters,
  readOnlyFilters,
  onClose,
  onApply,
  isMobilePhishing,
  isPolicyRules,
  isSast,
  isIssues,
  isPostmanProtect,
  isWebSecureChanges,
  isAntiFraud,
  isFullDataReport,
  isApiSecureThreats,
}) => {
  const classes = useStyles();

  const [selectedFilter, setSelectedFilter] = useState(null);
  const [FilterState, setFilterState] = useState(filters);

  let { data, loading, error } = {};

  const [defaultQuery, { data: defaultData, loading: defaultLoading, error: defaultError }] = useLazyQuery(
    assets_search_filter_values.list,
  );

  const [
    mobilePhishingQuery,
    { data: mobilePhishingData, loading: mobilePhishingLoading, error: mobilePhishingError },
  ] = useLazyQuery(mobile_phishing.discovered_apps_search_filter_values);

  const [
    webSecureChangesQuery,
    { data: webSecureChangesData, loading: webSecureChangesLoading, error: webSecureChangesError },
  ] = useLazyQuery(web_changes_search_filter_values);

  const [sastQuery, { data: sastData, loading: sastLoading, error: sastError }] = useLazyQuery(
    sast_issues_search_filter_values,
  );

  const [policyRulesQuery, { data: policyRulesData, loading: policyRulesLoading, error: policyRulesError }] =
    useLazyQuery(policy_rules.search_filter_values);

  const [antiFraudQuery, { data: antiFraudData, loading: antiFraudLoading, error: antiFraudError }] = useLazyQuery(
    risky_devices_search_filter_values,
  );

  const [
    apiProtectThreatsQuery,
    { data: apiProtectThreatsData, loading: apiProtectThreatsLoading, error: apiProtectThreatsError },
  ] = useLazyQuery(security_events_filter_values);

  const [
    getPostmanProtectTeamAndWorkspace,
    {
      data: postmanProtectTeamAndWorkspaceData,
      loading: postmanProtectTeamAndWorkspaceLoading,
      error: postmanProtectTeamAndWorkspaceError,
    },
  ] = useLazyQuery(postman_protect.get_table_filters);

  const [
    getSecurityFindingsFilters,
    { data: securityFindingsData, loading: securityFindingsLoading, error: securityFindingsError },
  ] = useLazyQuery(security_findings_search_filter_values);

  const {
    data: fullDataReportData,
    isFetching: fullDataReportLoading,
    error: fullDataReportError,
    refetch: getfullDataReportFilters,
  } = useTanstackQuery({
    enabled: false,
    queryFn: getPrivacyPleaseFullDataReportFilter,
    queryKey: ['assets_search_filter_values'],
  });

  // TODO: add full data report filter lazy query and use it in useEffect below

  useEffect(() => {
    if (isMobilePhishing) {
      mobilePhishingQuery();
    } else if (isSast) {
      sastQuery();
    } else if (isPolicyRules) {
      policyRulesQuery();
    } else if (isIssues) {
      getSecurityFindingsFilters();
    } else if (isPostmanProtect) {
      getPostmanProtectTeamAndWorkspace();
    } else if (isWebSecureChanges) {
      webSecureChangesQuery();
    } else if (isApiSecureThreats) {
      apiProtectThreatsQuery();
    } else if (isAntiFraud) {
      antiFraudQuery({
        variables: {
          ...assetsSearchFilterValuesQueryParams,
        },
      });
    } else if (isFullDataReport) {
      getfullDataReportFilters();
    } else {
      defaultQuery({
        variables: {
          ...assetsSearchFilterValuesQueryParams,
        },
      });
    }
  }, [
    mobilePhishingQuery,
    policyRulesQuery,
    sastQuery,
    defaultQuery,
    assetsSearchFilterValuesQueryParams,
    isMobilePhishing,
    isPolicyRules,
    isSast,
    isIssues,
    isPostmanProtect,
    isWebSecureChanges,
    webSecureChangesQuery,
    getPostmanProtectTeamAndWorkspace,
    getSecurityFindingsFilters,
    isAntiFraud,
    antiFraudQuery,
    isFullDataReport,
    getfullDataReportFilters,
    isApiSecureThreats,
    apiProtectThreatsQuery,
  ]);

  // TODO: augment this with full data report query status

  loading =
    defaultLoading ||
    mobilePhishingLoading ||
    policyRulesLoading ||
    sastLoading ||
    webSecureChangesLoading ||
    postmanProtectTeamAndWorkspaceLoading ||
    securityFindingsLoading ||
    antiFraudLoading ||
    postmanProtectTeamAndWorkspaceLoading ||
    fullDataReportLoading ||
    apiProtectThreatsLoading;
  data =
    defaultData ||
    mobilePhishingData ||
    policyRulesData ||
    sastData ||
    webSecureChangesData ||
    postmanProtectTeamAndWorkspaceData ||
    securityFindingsData;
  antiFraudData || postmanProtectTeamAndWorkspaceData;
  error =
    defaultError ||
    mobilePhishingError ||
    policyRulesError ||
    sastError ||
    webSecureChangesError ||
    postmanProtectTeamAndWorkspaceError ||
    securityFindingsError ||
    antiFraudError ||
    postmanProtectTeamAndWorkspaceError ||
    fullDataReportError ||
    apiProtectThreatsError;

  let filterValues = {};
  if (isMobilePhishing) {
    filterValues = data?.discovered_apps_search_filter_values.discovered_apps_search_filter_values;
  } else if (isPolicyRules) {
    filterValues = data?.policy_rules_search_filter_values.policy_rules_search_filter_values;
  } else if (isWebSecureChanges) {
    filterValues = data?.web_changes_search_filter_values.web_changes_search_filter_values;
  } else if (isSast) {
    filterValues = data?.sast_issues_search_filter_values.sast_issues_search_filter_values;
  } else if (isIssues) {
    filterValues = data?.security_findings_search_filter_values.security_findings_search_filter_values;
  } else if (isPostmanProtect) {
    filterValues = data?.postman_protect_get_table_filters.postman_protect_search_filter_values;
  } else if (isApiSecureThreats) {
    filterValues = data?.security_events_filter_values.security_events_filter_values;
  } else if (isAntiFraud) {
    filterValues = data?.risky_devices_search_filter_values.risky_devices_search_filter_values;
  } else if (isFullDataReport) {
    filterValues = data?.filters;
  } else {
    filterValues = data?.assets_search_filter_values.assets_search_filter_values;
  }

  if (error) {
    return (
      <Card>
        <Box p={1}>
          <ErrorState error={error.message} />
        </Box>
      </Card>
    );
  }

  if (loading) {
    return (
      <Card>
        <Box>
          <Box display={'flex'}>
            <Box className={classes.nav} p={1}>
              {[...new Array(6)].map((_, key) => (
                <Skeleton animation="wave" height={55} key={key} width={`100%`} />
              ))}
            </Box>
            <Box className={classes.box}>
              <Skeleton animation="wave" height={40} width={`100%`} />
            </Box>
          </Box>
          <Box className={classes.footer}>
            <Box ml={1}>
              <Skeleton animation="wave" height={48} width={75} />
            </Box>
            <Box ml={1}>
              <Skeleton animation="wave" height={48} width={75} />
            </Box>
          </Box>
        </Box>
      </Card>
    );
  }

  return (
    <Card>
      <Box>
        <Box display={'flex'} maxHeight={500}>
          <Box className={classes.nav}>
            <List className={classes.list}>
              {filterEnums.map((item, key) => (
                <TableFiltersSelectLabel
                  disabled={Boolean(readOnlyFilters[item])}
                  filterType={item}
                  key={key}
                  onClick={() => {
                    !readOnlyFilters[item] && setSelectedFilter(item);
                  }}
                  selected={Boolean(selectedFilter === item)}
                  title={filterItems[item]}
                />
              ))}
            </List>
          </Box>

          <Box className={classes.box}>
            {selectedFilter === 'filter_by_text' && (
              <TableFiltersKeyword
                filterKey={'filter_by_text'}
                filters={FilterState}
                label="Enter Keyword"
                onChange={filters => setFilterState(filters)}
                onKeyPress={event => {
                  if (event.key === 'Enter' && onApply) {
                    onApply(FilterState);
                  }
                }}
              />
            )}

            {selectedFilter === 'filter_by_discovered_application_keyword' && (
              <TableFiltersKeyword
                filterKey={'filter_by_discovered_application_keyword'}
                filters={FilterState}
                label="Enter Keyword"
                onChange={filters => setFilterState(filters)}
                onKeyPress={event => {
                  if (event.key === 'Enter' && onApply) {
                    onApply(FilterState);
                  }
                }}
              />
            )}

            {selectedFilter === 'filter_by_external_id' && (
              <TableFiltersKeyword
                filterKey={'filter_by_external_id'}
                filters={FilterState}
                label="Enter External ID"
                onChange={filters => setFilterState(filters)}
              />
            )}

            {selectedFilter === 'filter_by_asset_type' && (
              <TableFiltersListGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'ASSET_TYPE')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_asset_type: values,
                  })
                }
                searchLabel={'Search Asset Types'}
                selectedList={[...(FilterState.filter_by_asset_type || [])]}
              />
            )}

            {selectedFilter == 'filter_by_app_type' && (
              <TableFiltersAppType
                onChange={ids =>
                  setFilterState({
                    ...FilterState,
                    filter_by_asset_type: ids,
                  })
                }
                selectedList={FilterState.filter_by_asset_type || []}
              />
            )}

            {selectedFilter === 'filter_by_related_first_party_asset_type' && (
              <TableFiltersAppType
                onChange={ids =>
                  setFilterState({
                    ...FilterState,
                    filter_by_related_first_party_asset_type: ids,
                  })
                }
                selectedList={FilterState.filter_by_related_first_party_asset_type || []}
              />
            )}

            {selectedFilter === 'filter_by_platform' && (
              <TableFiltersFilterGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'MOBILE_APP_PLATFORM')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_platform: values,
                  })
                }
                searchLabel={'Search Platforms'}
                selectedList={[...(FilterState.filter_by_platform || [])]}
              />
            )}

            {selectedFilter === 'filter_by_type' && (
              <TableFiltersFilterGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'ISSUE_TYPE')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_type: values,
                  })
                }
                searchLabel={'Issue Source'}
                selectedList={[...(FilterState.filter_by_type || [])]}
              />
            )}

            {selectedFilter === 'filter_by_sast_issue_type' && (
              <TableFiltersFilterGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'ISSUE_TYPE')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_sast_issue_type: values,
                  })
                }
                searchLabel={'Issue Source'}
                selectedList={[...(FilterState.filter_by_sast_issue_type || [])]}
              />
            )}

            {selectedFilter === 'filter_by_web_change_type' && (
              <TableFiltersFilterGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'WEB_CHANGE_TYPE')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_web_change_type: values,
                  })
                }
                searchLabel={'Web Change Type'}
                selectedList={[...(FilterState.filter_by_web_change_type || [])]}
              />
            )}

            {selectedFilter === 'filter_by_discovered_via' && (
              <TableFiltersFilterGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'DISCOVERED_VIA')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_discovered_via: values,
                  })
                }
                searchLabel={'Search Discovery Sources'}
                selectedList={[...(FilterState.filter_by_discovered_via || [])]}
              />
            )}

            {selectedFilter === 'filter_by_cloud_provider' && (
              <TableFiltersFilterGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'CLOUD_PROVIDER')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_cloud_provider: values,
                  })
                }
                searchLabel={'Search Hosting Providers'}
                selectedList={[...(FilterState.filter_by_cloud_provider || [])]}
              />
            )}

            {selectedFilter === 'filter_by_asset_group_id' &&
              typeof FilterState.filter_by_asset_group_id !== 'string' && (
                <TableFiltersAssetGroup
                  isUngroupedAssetSelected={FilterState.filter_by_no_asset_group || false}
                  onChange={({ assetGroupIds, ungroupedAssetFlag }) =>
                    setFilterState({
                      ...FilterState,
                      filter_by_asset_group_id: assetGroupIds ?? FilterState.filter_by_asset_group_id,
                      filter_by_no_asset_group: ungroupedAssetFlag ?? FilterState.filter_by_no_asset_group,
                    })
                  }
                  selectedAssetGroupIds={FilterState.filter_by_asset_group_id || []}
                />
              )}

            {selectedFilter === 'filter_by_is_shadow' && (
              <TableFiltersShadowAssets
                onChange={value =>
                  setFilterState({
                    ...FilterState,
                    filter_by_is_shadow: value,
                  })
                }
                value={FilterState.filter_by_is_shadow || false}
              />
            )}

            {selectedFilter === 'filter_by_asset_tags' && (
              <TableFiltersAssetTag
                onChange={assetTags =>
                  setFilterState({
                    ...FilterState,
                    filter_by_asset_tags: assetTags,
                  })
                }
                selectedAssetTagsEncoded={FilterState.filter_by_asset_tags || '{}'}
              />
            )}

            {selectedFilter === 'filter_by_active_protection_status' && (
              <TableFiltersFilterGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'ACTIVE_PROTECTION_STATUS')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_active_protection_status: values,
                  })
                }
                searchLabel={'Search Active Protection Status'}
                selectedList={[...(FilterState.filter_by_active_protection_status || [])]}
              />
            )}

            {selectedFilter === 'filter_by_vulnerable_to_toolkit_hacks' && (
              <TableFiltersFilterGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'HACK_STATUS')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_vulnerable_to_toolkit_hacks: values,
                  })
                }
                searchLabel={'Search by Hack Status'}
                selectedList={[...(FilterState.filter_by_vulnerable_to_toolkit_hacks || [])]}
              />
            )}

            {selectedFilter == 'filter_by_uses_third_party_vendor_id' && (
              <TableFilterVendors
                onChange={ids =>
                  setFilterState({
                    ...FilterState,
                    filter_by_uses_third_party_vendor_id: ids,
                  })
                }
                selectedList={FilterState.filter_by_uses_third_party_vendor_id || []}
              />
            )}

            {selectedFilter === 'filter_by_infringement_level' && (
              <TableFiltersFilterGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'INFRINGEMENT_LEVEL')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_infringement_level: values,
                  })
                }
                searchLabel={'Search Fraudulent Behavior'}
                selectedList={[...(FilterState.filter_by_infringement_level || [])]}
              />
            )}

            {selectedFilter == 'filter_by_asset_type_group' && (
              <TableFiltersAssetTypeGroup
                onChange={ids =>
                  setFilterState({
                    ...FilterState,
                    filter_by_asset_type_group: ids,
                  })
                }
                selectedList={FilterState.filter_by_asset_type_group || []}
              />
            )}

            {selectedFilter === 'filter_by_discovered_app_status' && (
              <TableFiltersFilterGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'DISCOVERED_APP_STATUS')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_discovered_app_status: values,
                  })
                }
                searchLabel={'Search App Status'}
                selectedList={[...(FilterState.filter_by_discovered_app_status || [])]}
              />
            )}

            {selectedFilter == 'filter_by_mobile_app' && (
              <TableFilterMyApps
                appType={SupplyChainSecureAppTypeEnumValues.MOBILE_APPLICATION}
                onChange={ids =>
                  setFilterState({
                    ...FilterState,
                    filter_by_related_first_party_asset_id: ids,
                  })
                }
                selectedList={FilterState.filter_by_related_first_party_asset_id || []}
              />
            )}

            {selectedFilter === 'filter_by_triaging_decision' && (
              <TableFiltersFilterGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'TRIAGING_DECISION')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_triaging_decision: values,
                  })
                }
                searchLabel={'Search Action'}
                selectedList={[...(FilterState.filter_by_triaging_decision || [])]}
              />
            )}

            {selectedFilter == 'filter_by_web_app' && (
              <TableFilterMyApps
                appType={SupplyChainSecureAppTypeEnumValues.WEB_APPLICATION}
                onChange={ids =>
                  setFilterState({
                    ...FilterState,
                    filter_by_related_first_party_asset_id: ids,
                  })
                }
                selectedList={FilterState.filter_by_related_first_party_asset_id || []}
              />
            )}

            {selectedFilter == 'filter_by_severity' && (
              <TableFiltersSeverity
                onChange={ids =>
                  setFilterState({
                    ...FilterState,
                    filter_by_severity: ids.map(id => id?.toUpperCase()),
                  })
                }
                selectedList={FilterState.filter_by_severity || []}
              />
            )}

            {selectedFilter === 'filter_by_has_policy_violations_with_status' && (
              <TableFiltersFilterGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'HAS_POLICY_VIOLATIONS_WITH_STATUS')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_has_policy_violations_with_status: values,
                  })
                }
                searchLabel={'Status'}
                selectedList={[...(FilterState.filter_by_has_policy_violations_with_status || [])]}
              />
            )}

            {selectedFilter === 'filter_by_subscription' && (
              <TableFiltersFilterGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'MOBILE_APP_SUBSCRIPTION')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_subscription: values,
                  })
                }
                searchLabel={'Subscription'}
                selectedList={[...(FilterState.filter_by_subscription || [])]}
              />
            )}

            {selectedFilter === 'filter_by_belongs_to_products' && (
              <TableFiltersFilterGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'BELONGS_TO_PRODUCT')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_belongs_to_products: values,
                  })
                }
                searchLabel={'Products'}
                selectedList={[...(FilterState.filter_by_belongs_to_products || [])]}
              />
            )}

            {selectedFilter === 'filter_by_cnapp_category' && (
              <TableFiltersFilterGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'CNAPP_CATEGORY')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_cnapp_category: values,
                  })
                }
                searchLabel={'CNAPP Category'}
                selectedList={[...(FilterState.filter_by_cnapp_category || [])]}
              />
            )}

            {selectedFilter === 'filter_by_current_status' && (
              <TableFiltersListGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'CURRENT_STATUS')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_current_status: values,
                  })
                }
                searchLabel={'Current Status'}
                selectedList={[...(FilterState.filter_by_current_status || [])]}
              />
            )}

            {selectedFilter === 'filter_by_priority' && (
              <TableFiltersListGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'PRIORITY')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_priority: values,
                  })
                }
                searchLabel={'Current Status'}
                selectedList={[...(FilterState.filter_by_priority || [])]}
              />
            )}

            {selectedFilter === 'filter_by_compliance_policy' && (
              <TableFiltersListGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'COMPLIANCE_POLICY')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_compliance_policy: values,
                  })
                }
                searchLabel={'Current Status'}
                selectedList={[...(FilterState.filter_by_compliance_policy || [])]}
              />
            )}

            {selectedFilter === 'filter_by_release_type' && (
              <TableFiltersListGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'RELEASE_TYPE')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_release_type: values,
                  })
                }
                searchLabel={'Release Type'}
                selectedList={[...(FilterState.filter_by_release_type || [])]}
              />
            )}

            {selectedFilter === 'filter_by_store_blocker' && (
              <TableFiltersListGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'IMPORTANCE_TAGS')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_store_blocker: values,
                  })
                }
                searchLabel={'Store Blocker'}
                selectedList={[...(FilterState.filter_by_store_blocker || [])]}
              />
            )}

            {selectedFilter === 'filter_by_age' && (
              <PopoutFilterPluginMinimumAge
                onChange={value => {
                  setFilterState({
                    ...FilterState,
                    filter_by_age: value,
                  });
                }}
                onSubmit={() => {
                  setFilterState({
                    ...FilterState,
                  });
                }}
                value={FilterState?.filter_by_age || ''}
              />
            )}

            {selectedFilter === 'filter_by_hacker_toolkit' && (
              <TableFiltersListGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'HACKER_TOOLKIT')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_hacker_toolkit: values,
                  })
                }
                searchLabel={'Hacker Toolkit'}
                selectedList={[...(FilterState.filter_by_hacker_toolkit || [])]}
              />
            )}

            {selectedFilter === 'filter_by_workspace_name' && (
              <TableFiltersFilterGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'WORKSPACE')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_workspace_name: values,
                  })
                }
                searchLabel={'Search Workspaces'}
                selectedList={[...(FilterState.filter_by_workspace_name || [])]}
              />
            )}

            {selectedFilter === 'filter_by_team_name' && (
              <TableFiltersListGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'TEAM')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_team_name: values,
                  })
                }
                searchLabel={'Search Teams'}
                selectedList={[...(FilterState.filter_by_team_name || [])]}
              />
            )}

            {selectedFilter === 'filter_tests_by_status' && (
              <TableFilterTestsStatus
                list={filterValues?.filter(item => item?.belongs_to_filter === 'TEST_STATUS')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_tests_by_status: values,
                  })
                }
                searchLabel={'Test Status'}
                selectedList={[...(FilterState.filter_tests_by_status || [])]}
              />
            )}

            {selectedFilter === 'filter_by_user_id' && (
              <TableFiltersFilterGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'USER_ID')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_user_id: values,
                  })
                }
                searchLabel={'User ID'}
                selectedList={[...(FilterState.filter_by_user_id || [])]}
              />
            )}

            {selectedFilter === 'filter_by_countries' && (
              <TableFiltersListGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'COUNTRY')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_countries: values,
                  })
                }
                searchLabel={'Countries'}
                selectedList={[...(FilterState.filter_by_countries || [])]}
              />
            )}

            {selectedFilter === 'filter_by_source_country_code' && (
              <TableFiltersListGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'SOURCE_COUNTRY_CODE')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_source_country_code: values,
                  })
                }
                searchLabel={'Countries'}
                selectedList={[...(FilterState.filter_by_source_country_code || [])]}
              />
            )}

            {selectedFilter === 'filter_by_fraud_indicators' && (
              <TableFiltersListGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'FRAUD_INDICATORS')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_fraud_indicators: values,
                  })
                }
                searchLabel={'Fraud Signals'}
                selectedList={[...(FilterState.filter_by_fraud_indicators || [])]}
              />
            )}

            {selectedFilter === 'filter_by_app_versions' && (
              <TableFiltersListGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'APP_VERSION')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_app_versions: values,
                  })
                }
                searchLabel={'App Versions'}
                selectedList={[...(FilterState.filter_by_app_versions || [])]}
              />
            )}

            {selectedFilter === 'filter_by_event_type' && (
              <TableFiltersListGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'EVENT_TYPE')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_event_type: values,
                  })
                }
                searchLabel={'Event Type'}
                selectedList={[...(FilterState.filter_by_event_type || [])]}
              />
            )}

            {selectedFilter === 'filter_by_fraud_score' && (
              <TableFiltersListGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'FRAUD_SCORE')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_fraud_score: values,
                  })
                }
                searchLabel={'Fraud Score'}
                selectedList={[...(FilterState.filter_by_fraud_score || [])]}
              />
            )}
            {selectedFilter === 'filter_by_total_events' && (
              <TableFiltersListGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'TOTAL_EVENTS')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_total_events: values,
                  })
                }
                searchLabel={'Total Events'}
                selectedList={[...(FilterState.filter_by_total_events || [])]}
              />
            )}
            {selectedFilter === 'filter_by_fraud_activity' && (
              <TableFiltersListGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'FRAUD_ACTIVITY')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_fraud_activity: values,
                  })
                }
                searchLabel={'Fraud Activity'}
                selectedList={[...(FilterState.filter_by_fraud_activity || [])]}
              />
            )}
            {selectedFilter === 'filter_by_connections' && (
              <TableFiltersListGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'CONNECTIONS')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_connections: values,
                  })
                }
                searchLabel={'Connections'}
                selectedList={[...(FilterState.filter_by_connections || [])]}
              />
            )}

            {/* need to get date filter schema from Federico to format the values  */}
            {selectedFilter === 'filter_by_date_range' && <TableFiltersDateRange />}

            {selectedFilter === 'filter_by_report_status' && (
              <TableFiltersListGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'REPORT_STATUS')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_report_status: values,
                  })
                }
                searchLabel={'Current Status'}
                selectedList={[...(FilterState.filter_by_report_status || [])]}
              />
            )}

            {selectedFilter === 'filter_by_http_method' && (
              <TableFiltersListGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'HTTP_METHOD')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_http_method: values,
                  })
                }
                searchLabel={'HTTP Method'}
                selectedList={[...(FilterState.filter_by_http_method || [])]}
              />
            )}

            {selectedFilter === 'filter_by_is_priority_alert' && (
              <TableFiltersListGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'IS_PRIORITY_ALERT')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_is_priority_alert: values,
                  })
                }
                searchLabel={''}
              />
            )}

            {selectedFilter === 'filter_by_asset_id' && (
              <TableFiltersSourceCodeRepository
                label="Source Code Repository"
                onChange={filters => setFilterState(filters)}
                onKeyPress={event => {
                  if (event.key === 'Enter' && onApply) {
                    onApply(FilterState);
                  }
                }}
                selectedRepoIds={[...(FilterState.filter_by_asset_id || [])]}
              />
            )}

            {selectedFilter === 'filter_by_data_type' && (
              <TableFiltersListGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'DATA_TYPE')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_data_type: values,
                  })
                }
                searchLabel={'Data Type'}
                selectedList={[...(FilterState.filter_by_data_type || [])]}
              />
            )}

            {selectedFilter === 'filter_by_store_category' && (
              <TableFiltersListGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'STORE_CATEGORY')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_store_category: values,
                  })
                }
                searchLabel={'Store Category'}
                selectedList={[...(FilterState.filter_by_store_category || [])]}
              />
            )}

            {selectedFilter === 'filter_by_third_party_destinations' && (
              <TableFiltersListGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'DESTINATION')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_third_party_destinations: values,
                  })
                }
                searchLabel={'Third Party Destinations'}
                selectedList={[...(FilterState.filter_by_third_party_destinations || [])]}
              />
            )}

            {selectedFilter === 'filter_by_oss_sdk' && (
              <TableFiltersListGroup
                list={filterValues?.filter(item => item?.belongs_to_filter === 'OSS_SDK')}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_oss_sdk: values,
                  })
                }
                searchLabel={'OSS / SDK'}
                selectedList={[...(FilterState.filter_by_oss_sdk || [])]}
              />
            )}
          </Box>
        </Box>

        <Box className={classes.footer}>
          <Box>
            <Button onClick={onClose} variant={'text'}>
              Cancel
            </Button>
          </Box>
          <Box ml={1}>
            <Button
              color={'primary'}
              disabled={filters === FilterState}
              onClick={() => onApply(FilterState)}
              variant={'contained'}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default memo(AssetFilterPopper);
