import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Text from '../Text';
import { ExtLink } from '@dt/material-components';

export const AssetsAccessibleBanner = ({ asset, isLoading }) => {
  const accessibleUrl = asset?.included_cloud_resource?.cloud_console_url;

  if (isLoading) {
    return <Skeleton height={20} variant="rectangular" width={300} />;
  }

  if (!accessibleUrl) {
    // Should not happen.
    // Asset should be available when not loading.
    return null;
  }

  return (
    <Box>
      <Text style={{ display: 'inline' }} variant="body">
        Accessible at{' '}
        <ExtLink to={accessibleUrl}>
          <Text component="span" variant="link">
            {accessibleUrl}
          </Text>
        </ExtLink>
      </Text>
    </Box>
  );
};
